import { AfterViewChecked, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  IClubByFedTree,
  IRegistrationNewPricing,
  IRegistrationPricing,
  ShopDiscipline,
  PrismaPaymentOption as PaymentOption,
  PrismaPriceType as PriceType,
} from '@beathletics/api-interfaces';
import { TypedDialog } from '@beathletics/beathletics-ui';
import type { NewClub } from '@prisma/client';
import { priceValidator } from '../../../../../shared/custom-input-validators.directive';
import { TranslocoDirective } from '@jsverse/transloco';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatIcon } from '@angular/material/icon';
import { DisciplineByGroupSelectorComponent } from '../../../../shared/components/discipline-by-group-selector/discipline-by-group-selector.component';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { BeathleticsUiModule } from '@beathletics/beathletics-ui';
import { MatFormField, MatLabel, MatSuffix, MatError } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatButton } from '@angular/material/button';
import { MatDialogClose } from '@angular/material/dialog';

@Component({
  selector: 'beathletics-competition-registrations-pricing-dialog',
  templateUrl: './competition-registrations-pricing-dialog.component.html',
  standalone: true,
  imports: [
    TranslocoDirective,
    MatRadioGroup,
    ReactiveFormsModule,
    FormsModule,
    MatRadioButton,
    MatIcon,
    DisciplineByGroupSelectorComponent,
    MatSlideToggle,
    BeathleticsUiModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatSuffix,
    MatError,
    MatButton,
    MatDialogClose,
  ],
})
export class CompetitionRegistrationsPricingDialogComponent
  extends TypedDialog<
    {
      settings: { allowedOptions: PaymentOption[]; allowedTypes: PriceType[] };
      disciplinesByGroup: { [key: string]: { name: string; disciplines: ShopDiscipline[] } };
      clubList: IClubByFedTree[];
      pricing?: IRegistrationPricing;
    },
    Omit<IRegistrationNewPricing, 'eventNumber'> | IRegistrationPricing | null | undefined
  >
  implements AfterViewChecked, OnInit, OnDestroy
{
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  EPaymentOption = PaymentOption;
  selectedOption?: PaymentOption;
  EPriceType = PriceType;
  selectedPriceType?: PriceType;
  selectedDisciplines: ShopDiscipline[] = [];
  forAll = true;
  selectedClubs: string[] = [];
  price = new FormControl<number | null>(null, priceValidator());

  ngOnInit(): void {
    if (this.data.pricing) {
      this.selectedOption = this.data.pricing.option;
      this.selectedPriceType = this.data.pricing.type;
      this.price.setValue(this.data.pricing.priceInCent / 100);

      if (this.data.pricing.clubs.length > 0) {
        this.forAll = false;
      }
    }
  }

  ngAfterViewChecked(): void {
    this.#changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.#changeDetectorRef.detach();
  }

  setSelectedClubs(clubs: NewClub[]) {
    this.selectedClubs = clubs.map((club) => club.abbr);
  }

  savePricing(): void {
    const price = this.price.value;
    if (this.selectedPriceType && (price || price === 0)) {
      let pricing: IRegistrationPricing | undefined;
      const newPricing: Omit<IRegistrationNewPricing, 'eventNumber'> = {
        option: this.selectedOption || this.data.settings.allowedOptions[0],
        type: this.selectedPriceType,
        priceInCent: price * 100,
        disciplines: this.selectedDisciplines.map((d) => d.shopReference),
        clubs: this.selectedClubs,
      };
      if (this.data.pricing) {
        pricing = { ...this.data.pricing, ...newPricing };
      }
      this.dialogRef.close(pricing || newPricing);
    }
  }
}
