import { Injectable, inject } from '@angular/core';
import {
  DisciplineGroups,
  EFederationType,
  EPerfType,
  ERegistrationItemStatus,
  ERegistrationOrderStatus,
  IAthleteRegistrationWithCheck,
  IDisciplineMetadata,
  IRecordsCsvFileData,
  IRegistrationAthleteItem,
  IRegistrationDataAthlete,
  IRegistrationDataDiscipline,
  IRegistrationDataOrder,
  IRegistrationDisciplineItem,
  IRegistrationDisciplineMailData,
  IRegistrationDisciplineTableRow,
  IRegistrationPendingReminderMailData,
  IRegistrationResponseMailData,
  IRegistrationUnaffiliatedCsv,
  IRegistrationXlsData,
  IRegistrationsStats,
  IRelayCsvFileData,
  RegistrationAthleteInfo,
  RegistrationDisciplineInfo,
  ShopDiscipline,
} from '@beathletics/api-interfaces';
import { ApiServiceService, NewCompetitionService, SharedDataService } from '@beathletics/beathletics-data-state';
import { perfFormatterExport } from '@beathletics/beathletics-ui';
import {
  findCategoryFromDateAndGender,
  getActualAthleticsSeasonDates,
  getDateAsYYYMMDDString,
  getPerfTypeFromEventType,
} from '@beathletics/utils-shared';
import type { Gender } from '@prisma/client';
import { Observable, combineLatest, firstValueFrom, map, of } from 'rxjs';

const BIRTHDAY_COLUMN = 'dob';
const SEASON_BEST_BEATHLETICS = 'SB_beathletics';
const PERSONAL_BEST_BEATHLETICS = 'PB_beathletics';
const PERSONAL_BEST_PROVIDED = 'PB_provided';

@Injectable({
  providedIn: 'root',
})
export class CompetitionRegistrationService {
  private apiService = inject(ApiServiceService);
  private competitionService = inject(NewCompetitionService);
  private sharedDataService = inject(SharedDataService);

  getRegistrationDetailsByType(
    type: 'athlete' | 'discipline' | 'order',
    data: IRegistrationDataAthlete | IRegistrationDataDiscipline | IRegistrationDataOrder,
  ) {
    let toReturn: Observable<{
      [athleteId: string]: IRegistrationAthleteItem;
    }> | null;
    switch (type) {
      case 'athlete':
        toReturn = this.getRegistrationDetailsForAthlete(data as IRegistrationDataAthlete);
        break;
      case 'discipline':
        toReturn = this.getRegistrationDetailsForDiscipline(data as IRegistrationDataDiscipline);
        break;
      case 'order':
        toReturn = this.getRegistrationDetailsForOrder(data as IRegistrationDataOrder);
        break;
      default:
        toReturn = null;
        break;
    }
    return toReturn;
  }

  getRegistrationDetailsForAthlete(
    data: IRegistrationDataAthlete,
  ): Observable<{ [athleteId: string]: IRegistrationAthleteItem }> | null {
    const toTest = { [data.athleteId]: [] as string[] };
    const athleteDisciplines: {
      [disciplineRef_orderId: string]: IRegistrationDisciplineItem;
    } = {};
    if (data.disciplines) {
      for (const discipline of data.disciplines) {
        toTest[data.athleteId].push(discipline.disciplineRef);
        const metadata = discipline.data[data.athleteId].metadata;
        athleteDisciplines[discipline.disciplineRef + discipline.orderId] = {
          itemId: discipline.data[data.athleteId].id,
          orderId: discipline.orderId,
          orderEmail: discipline.orderEmail,
          status: discipline.status,
          name: discipline.name,
          athleteId: data.athleteId,
          perfType: discipline.perfType,
          group: discipline.group,
          userPerf: 'personalRecord' in metadata ? metadata.personalRecord : null,
          personalBest: null,
          seasonBest: null,
        };
      }
      return of({
        [data.athleteId]: {
          itemId: data.data.id,
          status: data.status,
          name: data.name,
          club: data.club,
          category: data.category,
          liveId: data.liveId,
          disciplines: athleteDisciplines,
        },
      });
    }
    return null;
  }

  getRegistrationDetailsForDiscipline(
    data: IRegistrationDataDiscipline,
  ): Observable<{ [athleteId: string]: IRegistrationAthleteItem }> | null {
    const toTest: { [athleteId: string]: string[] } = {};
    const disciplineAthletes: {
      [athleteId_orderId: string]: IRegistrationAthleteItem;
    } = {};
    const athleteToOrder: { [athleteId: string]: string[] } = {};
    if (data.athletes) {
      for (const athlete of data.athletes) {
        if (athlete.athleteId in athleteToOrder) {
          athleteToOrder[athlete.athleteId].push(athlete.orderId);
        } else {
          athleteToOrder[athlete.athleteId] = [athlete.orderId];
        }
        toTest[athlete.athleteId] = [data.disciplineRef];
        const metadata = data.data[athlete.athleteId].metadata as RegistrationDisciplineInfo;
        const disciplineData = athlete.disciplines?.find((d) => d.orderId === athlete.orderId);
        disciplineAthletes[athlete.athleteId + athlete.orderId] = {
          itemId: athlete.data.id,
          status: athlete.data.validationStatus,
          name: athlete.name,
          club: athlete.club,
          category: athlete.category,
          liveId: athlete.liveId,
          disciplines: {
            [data.disciplineRef]: {
              itemId: data.data[athlete.athleteId].id,
              orderId: disciplineData?.orderId || '',
              orderEmail: disciplineData?.orderEmail || '',
              status: disciplineData?.status || ERegistrationItemStatus.PENDING,
              name: data.name,
              athleteId: metadata.athleteId,
              relayData: metadata.relayTeam
                ? {
                    team: metadata.relayTeam?.name || '',
                    athletes: metadata.relayAthletes || [],
                  }
                : undefined,
              perfType: data.perfType,
              group: data.group,
              userPerf: 'personalRecord' in metadata ? metadata.personalRecord : null,
              personalBest: null,
              seasonBest: null,
            },
          },
        };
      }
      return this.apiService.getPersonalRecordsOfAthletesForDisciplines(toTest).pipe(
        map((res) => {
          const value = disciplineAthletes;
          for (const athleteId in res) {
            for (const orderId of athleteToOrder[athleteId]) {
              const disciplines = value[athleteId + orderId].disciplines;
              if (disciplines && data.disciplineRef === res[athleteId][0].disciplineRef) {
                disciplines[data.disciplineRef].personalBest = res[athleteId][0].personalBest;
                disciplines[data.disciplineRef].seasonBest = res[athleteId][0].seasonBest;
              }
            }
          }
          return value;
        }),
      );
    }
    return null;
  }

  getRegistrationDetailsForOrder(
    data: IRegistrationDataOrder,
  ): Observable<{ [athleteId: string]: IRegistrationAthleteItem }> | null {
    const toTest: { [athleteId: string]: string[] } = {};
    const disciplinesByAthlete: {
      [athleteId: string]: IRegistrationAthleteItem;
    } = {};
    for (const item of data.data.items) {
      if ('name' in item.metadata) {
        toTest[item.metadata.athleteId] = [];
        disciplinesByAthlete[item.metadata.athleteId] = {
          itemId: item.id,
          status: item.validationStatus,
          name: item.metadata.name,
          club: item.metadata.club || '',
          category: item.metadata.category || '',
          liveId: item.metadata.liveId || '',
          disciplines: {
            ...disciplinesByAthlete[item.metadata.athleteId]?.disciplines,
          },
        };
      } else {
        if (!toTest[item.metadata.athleteId]) {
          toTest[item.metadata.athleteId] = [];
        }
        toTest[item.metadata.athleteId].push(item.product.reference);
        if (disciplinesByAthlete[item.metadata.athleteId]) {
          const disciplines = disciplinesByAthlete[item.metadata.athleteId].disciplines;
          if (disciplines) {
            const disciplineData = this.competitionService.getLoadedDisciplineByRef(item.product.reference);
            disciplines[item.product.reference] = {
              itemId: item.id,
              orderId: item.orderId,
              orderEmail: data.data.client.email,
              status: item.validationStatus,
              name: item.product.name,
              athleteId: item.metadata.athleteId,
              relayData: item.metadata.relayTeam
                ? {
                    team: item.metadata.relayTeam?.name || '',
                    athletes: item.metadata.relayAthletes || [],
                  }
                : undefined,
              perfType: getPerfTypeFromEventType(disciplineData?.eventType),
              group: (disciplineData?.eventType?.discipline_group as DisciplineGroups) || null,
              userPerf: 'personalRecord' in item.metadata ? item.metadata.personalRecord : null,
              personalBest: null,
              seasonBest: null,
            };
          }
        } else {
          const disciplineData = this.competitionService.getLoadedDisciplineById(item.product.reference);
          disciplinesByAthlete[item.metadata.athleteId] = {
            itemId: '',
            status: ERegistrationItemStatus.PENDING,
            name: '',
            club: '',
            category: '',
            liveId: '',
            disciplines: {
              [item.product.reference]: {
                itemId: item.id,
                orderId: item.orderId,
                orderEmail: data.data.client.email,
                status: item.validationStatus,
                name: item.product.name,
                athleteId: item.metadata.athleteId,
                relayData: item.metadata.relayTeam
                  ? {
                      team: item.metadata.relayTeam?.name || '',
                      athletes: item.metadata.relayAthletes || [],
                    }
                  : undefined,
                perfType: getPerfTypeFromEventType(disciplineData?.eventType),
                group: (disciplineData?.eventType?.discipline_group as DisciplineGroups) || null,
                userPerf: 'personalRecord' in item.metadata ? item.metadata.personalRecord : null,
                personalBest: null,
                seasonBest: null,
              },
            },
          };
        }
      }
    }
    return this.apiService.getPersonalRecordsOfAthletesForDisciplines(toTest).pipe(
      map((res) => {
        const value = disciplinesByAthlete;
        for (const athleteId in res) {
          const disciplines = value[athleteId].disciplines;
          for (const d of res[athleteId]) {
            if (disciplines && disciplines[d.disciplineRef]) {
              disciplines[d.disciplineRef].personalBest = d.personalBest;
              disciplines[d.disciplineRef].seasonBest = d.seasonBest;
            }
          }
        }
        return value;
      }),
    );
  }

  groupAthletesByRelayTeamForDisciplineDetails(
    data: IRegistrationDisciplineTableRow[],
  ): IRegistrationDisciplineTableRow[] {
    const athletesByTeam: {
      [itemId: string]: IRegistrationDisciplineTableRow;
    } = {};
    for (const d of data) {
      if (!(d.itemId in athletesByTeam)) {
        athletesByTeam[d.itemId] = {
          athleteId: '',
          club: '',
          category: '',
          itemId: d.itemId,
          liveId: '',
          name: d.relayData?.team || '',
          group: d.group,
          orderId: d.orderId,
          orderEmail: d.orderEmail,
          status: d.status,
          perfType: d.perfType,
          userPerf: null,
          personalBest: null,
          seasonBest: null,
          relay: [
            {
              ...d,
              relayOrder: d.relayData?.athletes.find((a) => a.name === d.name)?.order,
            },
          ],
        };
      } else {
        (athletesByTeam[d.itemId].relay ?? []).push({
          ...d,
          relayOrder: d.relayData?.athletes.find((a) => a.name === d.name)?.order,
        });
      }
    }
    for (const itemId in athletesByTeam) {
      athletesByTeam[itemId].relay?.sort((a, b) => (a.relayOrder || 1) - (b.relayOrder || 1));
    }
    return Object.values(athletesByTeam).sort((a, b) => a.name.localeCompare(b.name));
  }

  getRegistrationDataForFileExport(data: {
    orders: IRegistrationDataOrder[];
    athletes: IRegistrationDataAthlete[];
    disciplines: IRegistrationDataDiscipline[];
  }) {
    const orderData: {
      [orderId: string]: {
        email: string;
        status: ERegistrationOrderStatus;
        submittedAt: string | null;
      };
    } = {};
    for (const order of data.orders) {
      orderData[order.orderId] = {
        email: order.email,
        status: order.data.status,
        submittedAt: order.data.submittedAt ? new Date(order.data.submittedAt).toLocaleString('fr-BE') : null,
      };
    }
    const competition = this.competitionService.getSelectedCompetitionSnapshot();
    const seasonDates = getActualAthleticsSeasonDates(competition.startDate);

    const athletesData$ = this.apiService.getAthletesMinimalData(data.athletes.map((a) => a.athleteId));
    const disciplinesData = data.disciplines.map((d) =>
      this.competitionService.getLoadedDisciplineByRef(d.disciplineRef),
    );
    const recordsData$ = this.apiService.getPersonalRecordsOfAthletesForDisciplines(
      data.athletes.reduce(
        (acc, a) => {
          acc[a.athleteId] = a.disciplines?.map((d) => d.disciplineRef) || [];
          return acc;
        },
        {} as { [athleteId: string]: string[] },
      ),
    );
    return combineLatest([athletesData$, of(data.athletes), recordsData$]).pipe(
      map(([athletesData, athletes, recordsData]) => {
        const fileData: IRegistrationXlsData[] = [];
        const unaffiliatedData: IRegistrationUnaffiliatedCsv[] = [];
        const combinedEventsData: IRecordsCsvFileData[] = [];
        const relayData: IRelayCsvFileData[] = [];

        for (const athlete of athletes) {
          const athleteData = athletesData.find((a) => a.id === athlete.athleteId);
          const athleteMetadata =
            (
              athlete.data.metadata as {
                relayAthletes: RegistrationAthleteInfo[];
              }
            )?.relayAthletes?.find((a) => a.athleteId === athlete.athleteId) || athlete.data.metadata;
          const oneDayBib =
            athlete.isOneDayBib && 'affiliationNumber' in athleteMetadata ? athleteMetadata.bib || 0 : 0;
          const affiliationNumber =
            'affiliationNumber' in athleteMetadata ? athleteMetadata.affiliationNumber || 'NC' : 'NC';
          // ? why isn't this localTeam used anymore ? need to keep it ?
          /* const localTeam =
            'affiliationNumber' in athleteMetadata
              ? athleteMetadata.localTeam || 'NC'
              : 'NC'; */
          const federationType =
            athleteData?.club?.federation?.abbr === 'LBFA'
              ? EFederationType.LBFA
              : athleteData?.club?.federation?.abbr === 'VAL'
                ? EFederationType.VAL
                : EFederationType.WA;

          if (athleteData && athlete && athlete?.disciplines) {
            for (const discipline of athlete.disciplines) {
              const disciplineData = disciplinesData.find((d) => d?.shopReference === discipline.disciplineRef);
              const metadata = discipline?.data?.[athlete?.athleteId]?.metadata;
              const personalBest = 'personalRecord' in metadata ? metadata.personalRecord : 0;
              const records = recordsData?.[athlete?.athleteId]?.find(
                (r) => r.disciplineRef === discipline.disciplineRef,
              );
              const resultType = disciplineData?.eventType?.result_type
                ? (disciplineData.eventType.result_type.toLowerCase() as EPerfType)
                : undefined;
              const eventTypeGroup = disciplineData?.eventType?.discipline_group
                ? (disciplineData.eventType.discipline_group as DisciplineGroups)
                : undefined;

              const inconsistency = this.checkIfPerfInconsistency(
                personalBest,
                resultType === EPerfType.TIME,
                records?.personalBest,
              );
              if (disciplineData) {
                const birthDate = athleteData?.person?.birthDate;
                const data: IRegistrationXlsData = {
                  AM_ID: (disciplineData.metadata as IDisciplineMetadata)?.id_am || 0,
                  club: athlete.club,
                  federation: federationType,
                  comp_id: competition.eventNumber,
                  competition_name: competition.name,
                  license_number: athleteData?.liveId || affiliationNumber,
                  athlete_bib: athleteData?.bib || oneDayBib,
                  firstname: athleteData.person.firstName,
                  lastname: athleteData.person.lastName,
                  email: orderData[discipline.orderId].email,
                  status_order: orderData[discipline.orderId].status,
                  submitted_at: orderData[discipline.orderId].submittedAt || '-',
                  status_item: discipline.status,
                  [BIRTHDAY_COLUMN]: birthDate ? new Date(birthDate).toLocaleDateString() : 'NC',
                  gender: athleteData.person.gender === 'Female' ? 'F' : 'M',
                  category:
                    birthDate && athleteData.person.gender
                      ? findCategoryFromDateAndGender(new Date(birthDate), athleteData.person.gender, true)
                      : 'NC',
                  event_type_code: +(disciplineData.eventType?.type_id || 0),
                  event_name: disciplineData.name,
                  [PERSONAL_BEST_PROVIDED]: perfFormatterExport(personalBest, resultType, eventTypeGroup),
                  [PERSONAL_BEST_BEATHLETICS]: perfFormatterExport(records?.personalBest, resultType, eventTypeGroup),
                  [SEASON_BEST_BEATHLETICS]: perfFormatterExport(records?.seasonBest, resultType, eventTypeGroup),
                  relay: eventTypeGroup === DisciplineGroups.Relais,
                };
                if (inconsistency) {
                  data.inconsistency = 'true';
                }
                fileData.push(data);
                if (data.relay) {
                  relayData.push({
                    'db_events.seqno*': (disciplineData.metadata as IDisciplineMetadata)?.id_am || 0,
                    'db_licenses.licensenumber*': athleteData?.liveId || affiliationNumber,
                    'db_participants.seqno': athlete.relayOrder?.[discipline.disciplineRef] || 0,
                    'db_participations.teamname': athlete.relayTeam?.[discipline.disciplineRef] || '',
                    'db_records.value': personalBest,
                  });
                }
                if (athlete.isOneDayBib && data.status_item !== ERegistrationItemStatus.REJECTED) {
                  const alreadyInArray = unaffiliatedData.find(
                    (d) => d['db_licenses.licensenumber*'] === data.license_number,
                  );
                  if (!alreadyInArray) {
                    unaffiliatedData.push({
                      'db_athletes.firstname': athleteData.person.firstName,
                      'db_athletes.middlename': '',
                      'db_athletes.lastname': athleteData.person.lastName,
                      'db_athletes.gender': data.gender === 'F' ? 'W' : 'M',
                      'db_athletes.nationality': this.sharedDataService.getAMCountryIso3ByAbbrev(
                        athleteData.person.nationality,
                      ),
                      'db_athletes.birthdate': birthDate ? getDateAsYYYMMDDString(new Date(birthDate)) : '',
                      'db_licenses.startdate': getDateAsYYYMMDDString(new Date(seasonDates.from)),
                      'db_licenses.endate': getDateAsYYYMMDDString(new Date(seasonDates.to)),
                      'db_teams.federationnumber': this.sharedDataService.getAMCountryCodeByAbbrev(
                        athleteData?.federation?.abbr || athleteData.person.nationality,
                        true,
                      ),
                      'db_teams.abbreviation': data.club,
                      'db_licenses.team': athleteData.club?.fedNumber || athleteData.federation?.fedNumber || 999,
                      'db_licenses.licensenumber*': data.license_number,
                      'db_licenses.id_for_federation': data.license_number,
                      'db_licenses.bib': data.athlete_bib,
                    });
                  }
                }

                if (
                  'subRecords' in metadata &&
                  disciplineData.children &&
                  data.status_item !== ERegistrationItemStatus.REJECTED
                ) {
                  for (const disciplineRef in metadata.subRecords) {
                    const record = metadata.subRecords[disciplineRef];
                    const childDiscipline = disciplineData.children.find(
                      (d: ShopDiscipline) => d?.shopReference === disciplineRef,
                    );
                    if (childDiscipline && record) {
                      let perf = +record?.personalRecord || 0;
                      const eventType = childDiscipline.eventType;
                      if ((eventType?.result_type || '').toLowerCase() === EPerfType.TIME) {
                        perf = +perf / 1000;
                      }
                      combinedEventsData.push({
                        'db_licenses.licensenumber*': athleteData?.liveId || affiliationNumber,
                        'db_eventtypes.nationalcode*': eventType?.national_code || '',
                        'db_records.date': '',
                        'db_records.value': perf,
                        'db_records.wind': 0,
                        'db_records.seasonflag': 0,
                        'db_records.competitionrecord': 0,
                        'db_records.stadionrecord': 0,
                        'db_records.alltimeflag': 1,
                      });
                    }
                  }
                }
              }
            }
          }
        }
        return {
          all: fileData.filter((d) => d.status_item !== ERegistrationItemStatus.REJECTED),
          unaffiliated: unaffiliatedData,
          combined_events: combinedEventsData,
          relay_athletes: relayData
            .sort((a, b) => a['db_participants.seqno'] - b['db_participants.seqno'])
            .sort((a, b) => a['db_participations.teamname'].localeCompare(b['db_participations.teamname'])),
        };
      }),
    );
  }

  checkIfPerfInconsistency(provided: string | number, isTimePerf: boolean, best?: number | null) {
    if (provided && best && typeof provided === 'number') {
      if (isTimePerf) {
        // ! this does not work if provided time is in minutes !
        // + if the provided time is a string it may cause issues
        // TODO : find a better way to compare performances
        // ? will it be fixed with the upgrade of the perf field ?
        return provided * 1000 < best;
      } else {
        return provided > best;
      }
    }
    return false;
  }

  validateOrCancelOrder(
    orderId: string,
    type: 'confirmation' | 'cancellation' | 'detailed',
    mailData: IRegistrationResponseMailData,
  ) {
    if (type === 'confirmation') {
      return this.apiService.validateOrder(orderId, mailData, true);
    } else if (type === 'cancellation') {
      return this.apiService.cancelOrder(orderId, mailData);
    } else {
      return this.apiService.validateOrderWithSomeRefusals(orderId, mailData);
    }
  }

  removeItemsFromOrder(orderId: string, itemsId: string[]) {
    return this.apiService.removeProductsFromOrder(itemsId, orderId);
  }

  async getEmailDataForOrder(order: IRegistrationDataOrder, type: 'confirmation' | 'cancellation') {
    const data = this.getRegistrationDetailsForOrder(order);
    const registrations = data ? await firstValueFrom(data) : null;
    if (registrations) {
      const formattedData = {
        athletes: Object.values(registrations).map((r) => ({
          ...r,
          checked: type === 'confirmation',
          disciplines: Object.values(r.disciplines).map((d) => ({
            ...d,
            checked: type === 'confirmation',
          })),
        })),
      };
      return this.formatDataFormRegistrationResponseMail(order.email, formattedData.athletes);
    }
    return null;
  }

  formatDataFormRegistrationResponseMail(
    email: string,
    athletes: IAthleteRegistrationWithCheck[],
  ): IRegistrationResponseMailData {
    const competition = this.competitionService.getSelectedCompetitionSnapshot();
    return {
      contactEmail: email,
      eventName: competition.name,
      eventNumber: competition.eventNumber,
      eventDate: new Date(competition.startDate).toLocaleDateString(),
      organizerEmail: competition.registrationDetail?.contactEmail || competition.club?.abbr.toLowerCase() + '@lbfa.be',
      organizerName: competition.club?.name || competition?.federation?.name || 'ORGANIZER NAME NOT FOUND',
      athletes: athletes.map((a) => ({
        fullName: a.name,
        club: a.club,
        category: a.category,
        itemId: a.itemId,
        accepted: a.checked ? 'Accepté' : 'Refusé',
        disciplines: a.disciplines.map((d) => ({
          name: d.name,
          itemId: d.itemId,
          accepted: d.checked ? 'Accepté' : 'Refusé',
        })),
      })),
    };
  }

  sendReminderMailToPendingOrder(data: IRegistrationPendingReminderMailData) {
    return this.apiService.sendPendingReminderEmail(data);
  }

  validateOrRefuseForDiscipline(validate: boolean, data: IRegistrationDisciplineMailData[]) {
    return this.apiService.validateOrRefuseDisciplineItems(validate ? 'validate' : 'refuse', data);
  }

  getEventRegistrationStats(): Observable<IRegistrationsStats> {
    return this.competitionService.getCurrentEventDataAndRegistrations().pipe(
      map((data) => {
        const result = {
          athletes: {
            affiliated: 0,
            unaffiliated: {
              total: 0,
              foreign: 0,
              others: 0,
            },
            total: 0,
          },
          categories: [] as string[],
          teams: {
            counts: {
              club: 0,
              federation: 0,
              other: 0,
              total: 0,
            },
            details: {},
          } as {
            counts: {
              club: number;
              federation: number;
              other: number;
              total: number;
            };
            details: {
              [abbr: string]: {
                data: {
                  name: string;
                  abbr: string;
                  type: 'club' | 'federation' | 'other';
                };
                participants: { [category: string]: string[] };
                participations: { [category: string]: number };
              };
            };
          },
        };
        const athleteData: { [athleteId: string]: RegistrationAthleteInfo } = {};

        for (const order of data.registrations) {
          if (order.status !== ERegistrationOrderStatus.CANCELLED) {
            // sort to have athletes first and set athletes & teams data
            const orderedItems = order.items.sort((a, b) => {
              if ('name' in a.metadata) {
                return -1;
              } else if ('comment' in a.metadata) {
                return 1;
              }
              if ('name' in b.metadata) {
                return 1;
              } else if ('comment' in b.metadata) {
                return -1;
              }
              return 0;
            });
            for (const item of orderedItems) {
              if (item.validationStatus !== ERegistrationItemStatus.REJECTED) {
                if ('name' in item.metadata) {
                  // = athlete registration (= participant)
                  if (!athleteData[item.metadata.athleteId]) {
                    athleteData[item.metadata.athleteId] = item.metadata;
                    result.athletes.total++;
                    if ('liveId' in item.metadata) {
                      result.athletes.affiliated++;
                    } else {
                      result.athletes.unaffiliated.total++;
                      if ('federation' in item.metadata) {
                        result.athletes.unaffiliated.foreign++;
                      } else {
                        result.athletes.unaffiliated.others++;
                      }
                    }
                  }
                  const orgaData = this.getOrganizationDataForStats(item.metadata);
                  if (orgaData && item.metadata.category) {
                    if (!result.categories.includes(item.metadata.category)) {
                      result.categories.push(item.metadata.category);
                    }

                    const key = `${orgaData.abbr}_${orgaData.type}`;
                    if (!result.teams.details[key]) {
                      result.teams.details[key] = {
                        data: orgaData,
                        participants: {
                          ['total']: [],
                        },
                        participations: {
                          ['total']: 0,
                        },
                      };
                      result.teams.counts.total++;
                      result.teams.counts[orgaData.type]++;
                    }
                    result.teams.details[key].participants['total'].push(item.metadata.athleteId);
                    if (!result.teams.details[key].participants[item.metadata.category]) {
                      result.teams.details[key].participants[item.metadata.category] = [];
                      result.teams.details[key].participations[item.metadata.category] = 0;
                    }
                    if (
                      !result.teams.details[key].participants[item.metadata.category].includes(item.metadata.athleteId)
                    ) {
                      result.teams.details[key].participants[item.metadata.category].push(item.metadata.athleteId);
                    }
                  }
                } else {
                  // = discipline registration (= participation)
                  const athlete = athleteData[item.metadata.athleteId];
                  const orgaData = this.getOrganizationDataForStats(athlete);
                  if (orgaData && athlete.category) {
                    const key = `${orgaData.abbr}_${orgaData.type}`;
                    result.teams.details[key].participations['total']++;
                    result.teams.details[key].participations[athlete.category]++;
                  }
                  if (item.metadata.relayAthletes && item.metadata.relayTeam) {
                    for (const a of item.metadata.relayAthletes) {
                      if (!athleteData[a.athleteId]) {
                        athleteData[a.athleteId] = athlete;
                        result.athletes.total++;
                        if ('liveId' in a) {
                          result.athletes.affiliated++;
                        } else {
                          result.athletes.unaffiliated.total++;
                          if ('federation' in a) {
                            result.athletes.unaffiliated.foreign++;
                          } else {
                            result.athletes.unaffiliated.others++;
                          }
                        }
                      }
                      const orgaData = this.getOrganizationDataForStats(a);
                      if (orgaData && a.category) {
                        if (!result.categories.includes(a.category)) {
                          result.categories.push(a.category);
                        }
                        const key = `${orgaData.abbr}_${orgaData.type}`;
                        if (!result.teams.details[key]) {
                          result.teams.details[key] = {
                            data: orgaData,
                            participants: {
                              ['total']: [],
                            },
                            participations: {
                              ['total']: 0,
                            },
                          };
                          result.teams.counts.total++;
                          result.teams.counts[orgaData.type]++;
                        }
                        result.teams.details[key].participants['total'].push(a.athleteId);
                        if (!result.teams.details[key].participants[a.category]) {
                          result.teams.details[key].participants[a.category] = [];
                          result.teams.details[key].participations[a.category] = 0;
                        }
                        if (!result.teams.details[key].participants[a.category].includes(a.athleteId)) {
                          result.teams.details[key].participants[a.category].push(a.athleteId);
                        }

                        result.teams.details[key].participations['total']++;
                        result.teams.details[key].participations[a.category]++;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        const toReturn = {
          ...result,
          teams: {
            ...result.teams,
            details: Object.values(result.teams.details)
              .sort((a, b) => a.data.name.localeCompare(b.data.name))
              .sort((a, b) => a.data.type.localeCompare(b.data.type)),
          },
        };
        return toReturn;
      }),
    );
  }

  getOrganizationDataForStats(data: RegistrationAthleteInfo): {
    name: string;
    abbr: string;
    type: 'club' | 'federation' | 'other';
  } | null {
    if ('federation' in data && typeof data.federation === 'string') {
      const federation = this.sharedDataService.getFederationByAbbrev(data.federation);
      if (federation) {
        return {
          name: federation.name,
          abbr: federation.abbr,
          type: 'federation',
        };
      }
    } else if ('localTeam' in data && typeof data.localTeam === 'string') {
      return {
        name: data.localTeam,
        abbr: data.localTeam,
        type: 'other',
      };
    } else if (data.club !== 'NA' && typeof data.club === 'string') {
      const club = this.sharedDataService.getClubByAbbrev(data.club);
      if (club) {
        return {
          name: club.name,
          abbr: club.abbr,
          type: 'club',
        };
      }
    }
    return null;
  }
}
