import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {
  IBibData,
  IClubByFedTree,
  IContact,
  IDiscipline,
  IRegistrationDataAthlete,
  IRegistrationDataDiscipline,
  IRegistrationDataOrder,
  IRegistrationPricing,
  IResult,
  KeycloakUser,
  ShopDiscipline,
  PrismaPaymentOption,
  PrismaPriceType,
} from '@beathletics/api-interfaces';
import type { Event, Results } from '@prisma/client';

import {
  ConfirmationDialogComponent,
  NotificationDialogComponent,
  TypedDialogService,
} from '@beathletics/beathletics-ui';
import { TranslocoService } from '@jsverse/transloco';
import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';
import { EventEditDialogComponent } from '../../components/event-edit-dialog/event-edit-dialog.component';
import { ResultEditDialogComponent } from '../../components/result-edit-dialog/result-edit-dialog.component';
import { CompetitionImportsInfoDialogComponent } from '../../pages/competition-admin/components/competition-imports-info-dialog/competition-imports-info-dialog.component';
import { CompetitionRegistrationItemDialogComponent } from '../../pages/competition-admin/components/competition-registration-item-dialog/competition-registration-item-dialog.component';
import { CompetitionRegistrationsBibDialogComponent } from '../../pages/competition-admin/components/competition-registrations-bib-dialog/competition-registrations-bib-dialog.component';
import { CompetitionRegistrationsLocalTeamFormComponent } from '../../pages/competition-admin/components/competition-registrations-local-team-form/competition-registrations-local-team-form.component';
import { CompetitionRegistrationsPricingDialogComponent } from '../../pages/competition-admin/components/competition-registrations-pricing-dialog/competition-registrations-pricing-dialog.component';
import { CompetitionResultEditDialogComponent } from '../../pages/competition-admin/components/competition-result-edit-dialog/competition-result-edit-dialog.component';
import { UserCreateDialogComponent } from '../../pages/user-admin/components/user-create-dialog/user-create-dialog.component';
import { UserEditDialogComponent } from '../../pages/user-admin/components/user-edit-dialog/user-edit-dialog.component';
import { CompetitionCreationDialogComponent } from '../components/competition-creation-dialog/competition-creation-dialog.component';
import { ContactSelectionDialogComponent } from '../components/contact-selection-dialog/contact-selection-dialog.component';
import { EventTypeSelectionDialogComponent } from '../components/event-type-selection-dialog/event-type-selection-dialog.component';
import {
  FormDefinition,
  GenericFormDialogComponent,
} from '../components/genericFormDialog/generic-form-dialog.component';
import { MergeAthletesDialogComponent } from '../components/merge-athletes-dialog/merge-athletes-dialog.component';
// FIXME: delete this service to allow better code loading

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  #transloco = inject(TranslocoService);

  dialog = inject(MatDialog);
  typedDialog = inject(TypedDialogService);

  defaultOptions: MatDialogConfig = {
    width: '',
    height: '',
    maxWidth: '96vw',
    maxHeight: '98vh',
    panelClass: '',
    data: {},
  };

  openNotificationDialog(title: string, texts: string[]) {
    const options = this.defaultOptions;
    options.data = { title, texts };
    const dialogRef: MatDialogRef<NotificationDialogComponent> = this.dialog.open(NotificationDialogComponent, options);
    return dialogRef.afterClosed();
  }

  openAlertDialog(title: string, text: string) {
    const options = { ...this.defaultOptions, data: { title, text } };
    const dialogRef = this.typedDialog.open(AlertDialogComponent, options);
    return dialogRef.afterClosed();
  }

  openEditEventDialog(event: Event) {
    const options = this.defaultOptions;
    options.data = event;
    const dialogRef: MatDialogRef<EventEditDialogComponent> = this.dialog.open(EventEditDialogComponent, options);
    return dialogRef.afterClosed();
  }

  openEditResultDialog(result: Results) {
    const options = this.defaultOptions;
    options.data = result;
    const dialogRef: MatDialogRef<ResultEditDialogComponent> = this.dialog.open(ResultEditDialogComponent, options);
    return dialogRef.afterClosed();
  }

  openCreateUserDialog() {
    const options = this.defaultOptions;
    const dialogRef: MatDialogRef<UserCreateDialogComponent> = this.dialog.open(UserCreateDialogComponent, options);
    return dialogRef.afterClosed();
  }

  openEditUserDialog(userData: KeycloakUser) {
    const options = this.defaultOptions;
    options.data = userData;
    const dialogRef: MatDialogRef<UserEditDialogComponent> = this.dialog.open(UserEditDialogComponent, options);
    return dialogRef.afterClosed();
  }

  openCompetitionEditResultDialog(
    data: {
      discipline: IDiscipline;
      result: IResult;
    },
    addResult = false,
  ) {
    const options = this.defaultOptions;
    options.data = { ...data, addResult };
    const dialogRef: MatDialogRef<CompetitionResultEditDialogComponent> = this.dialog.open(
      CompetitionResultEditDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  openEventTypeSelectionDialog(discipline?: { id: string; parentId?: string | null }) {
    const options = this.defaultOptions;
    if (discipline) {
      options.data = {
        disciplineId: discipline.id,
        parentId: discipline?.parentId,
      };
    }
    const dialogRef: MatDialogRef<EventTypeSelectionDialogComponent> = this.dialog.open(
      EventTypeSelectionDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  openCompetitionImportInfoDialog(ja: IContact | null, onlySchedule = false) {
    const options = this.defaultOptions;
    options.data = { ja, onlySchedule };
    const dialogRef: MatDialogRef<CompetitionImportsInfoDialogComponent> = this.dialog.open(
      CompetitionImportsInfoDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  openContactSelectionDialog(eventNumber: string) {
    const options = this.defaultOptions;
    options.data = { eventNumber };
    const dialogRef: MatDialogRef<ContactSelectionDialogComponent> = this.dialog.open(
      ContactSelectionDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  openCompetitionCreationDialog() {
    const options = this.defaultOptions;
    const dialogRef: MatDialogRef<CompetitionCreationDialogComponent> = this.dialog.open(
      CompetitionCreationDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  openConfirmationDialog(title: string, message: string) {
    // * send translation keys for title and message, they're translated in the dialog
    const options = this.defaultOptions;
    options.data = { title, message };
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> = this.dialog.open(ConfirmationDialogComponent, options);
    return dialogRef.afterClosed();
  }

  openRegistrationItemDetailDialog(
    data: IRegistrationDataAthlete | IRegistrationDataDiscipline | IRegistrationDataOrder,
  ) {
    const options = this.defaultOptions;
    options.data = data;
    const dialogRef: MatDialogRef<CompetitionRegistrationItemDialogComponent> = this.dialog.open(
      CompetitionRegistrationItemDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  openRegistrationsBibDialog(bibs: IBibData[]) {
    const options = {
      ...this.defaultOptions,
      panelClass: 'mat-dialog-overflow-visible',
    };
    options.data = { bibs };
    const dialogRef: MatDialogRef<CompetitionRegistrationsBibDialogComponent> = this.dialog.open(
      CompetitionRegistrationsBibDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  openGenericFormDialog<T>(data: FormDefinition<T>) {
    const options = this.defaultOptions;
    options.data = data;
    const dialogRef: MatDialogRef<GenericFormDialogComponent<T>> = this.dialog.open(
      GenericFormDialogComponent<T>,
      options,
    );
    return dialogRef.afterClosed();
  }

  openCreateLocalTeamDialog() {
    const options = this.defaultOptions;
    const dialogRef: MatDialogRef<CompetitionRegistrationsLocalTeamFormComponent> = this.dialog.open(
      CompetitionRegistrationsLocalTeamFormComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  openAthleteMergingToolDialog() {
    const options = this.defaultOptions;
    const dialogRef: MatDialogRef<MergeAthletesDialogComponent> = this.dialog.open(
      MergeAthletesDialogComponent,
      options,
    );
    return dialogRef.afterClosed();
  }

  async openErrorDialog(error: any) {
    let title = await this.#transloco.translate('GENERIC_ERROR.TITLE'); // default en: Error, fr: Erreur
    let texts: string[] = [];

    if (error.error && typeof error.error === 'string') {
      title = error.error;
      texts.push(error.error);
    } else if (error.error) {
      if (error.error.code === 'DISCIPLINE_CHANGED') {
        title = await this.#transloco.translate('ERRORS.HTTP422.DISCIPLINE_CHANGED.TITLE');
        texts.push(
          await this.#transloco.translate('ERRORS.HTTP422.DISCIPLINE_CHANGED', {
            missingIds: error.error.details.missingDisciplines.join(', '),
          }),
        );
      } else {
        texts = [error.status + ' ' + error.statusText, error.error.message];
      }
    } else {
      texts = [error.status + ' ' + error.statusText, error.message];
    }

    this.openNotificationDialog(title, texts);
  }

  openRegistrationsPricingTypedDialog(
    settings: { allowedOptions: PrismaPaymentOption[]; allowedTypes: PrismaPriceType[] },
    disciplinesByGroup: {
      [key: string]: { name: string; disciplines: ShopDiscipline[] };
    },
    clubList: IClubByFedTree[],
    pricing?: IRegistrationPricing,
  ) {
    const options = {
      ...this.defaultOptions,
      panelClass: 'mat-dialog-overflow-visible',
      data: { settings, disciplinesByGroup, clubList, pricing },
    };
    const dialogRef = this.typedDialog.open(CompetitionRegistrationsPricingDialogComponent, options);
    return dialogRef.afterClosed();
  }
}
