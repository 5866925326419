import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { filter, firstValueFrom, map, take, tap } from 'rxjs';
import { AuthService } from './auth.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  protected readonly router: Router;
  protected readonly keycloak: KeycloakService;
  protected readonly authService = inject(AuthService);
  protected readonly snackbar = inject(MatSnackBar);
  #platformId = inject(PLATFORM_ID);
  constructor() {
    const router = inject(Router);
    const keycloak = inject(KeycloakService);

    super(router, keycloak);

    this.router = router;
    this.keycloak = keycloak;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    //add console log with route
    if (this.authService.isEnabled) {
      return this.isAccessAllowed(route, state);
    } else {
      console.log('blocked');
      console.log('authService.isEnabled()', this.authService.isEnabled);
      return Promise.resolve(false);
    }
    // Exécuter super.canActivate au prochain tick
  }
  isAccessAllowed = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> => {
    console.log('isAccessAllowed');
    return firstValueFrom(
      this.authService.isReady.pipe(
        tap((isReady) => {
          console.log('isAccessAllowed isReady', isReady);
        }),
        filter((isReady) => isReady),
        take(1),
        map(() => {
          return this.checkRoleOnReady(route);
        }),
        tap((result) => {
          console.log('isAccessAllowed result', result);
        }),
      ),
    );
  };

  checkRoleOnReady = (route: ActivatedRouteSnapshot) => {
    if (!this.authService.getInstance().authenticated) {
      this.handleNotAuthenticated();
    }
    if (!this.checkNeededRole(route)) {
      return this.handleRoleNotFound();
    }
    return true;
  };

  checkNeededRole = (route: ActivatedRouteSnapshot) => {
    let hasOneRoleNeeded = false;
    if (route.data.neededRole instanceof Array) {
      for (const role of route.data.neededRole) {
        hasOneRoleNeeded = hasOneRoleNeeded || this.authService.getInstance().hasRealmRole(role);
      }
    }
    return hasOneRoleNeeded;
  };

  handleNotAuthenticated() {
    return this.keycloak.login({
      redirectUri:
        window.location.origin + this.router.getCurrentNavigation()?.finalUrl?.toString() ||
        this.router.getCurrentNavigation()?.extractedUrl.toString(),
    });
  }

  handleRoleNotFound() {
    const snackBarRef = this.snackbar.open('Accès Refusé', 'OK');
    snackBarRef.afterDismissed().subscribe(() => this.router.navigate(['/']));
    return false;
  }
}
