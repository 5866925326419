<section *transloco="let t" class="flex flex-col w-96">
  <header class="text-center">
    <h3 class="text-xl font-medium mb-4">
      @if (data.pricing) {
        {{ t('REGISTRATION_PRICING_EDIT') }}
      } @else {
        {{ t('REGISTRATION_PRICING_ADD') }}
      }
    </h3>
    <hr />
  </header>
  <div class="flex flex-col">
    <label class="mt-4 mb-2">{{ t('REGISTRATION_RATE_APPLICATION') }} :</label>
    @if (data.settings.allowedOptions.length > 1) {
      <mat-radio-group class="flex gap-4" [(ngModel)]="selectedOption">
        @for (option of data.settings.allowedOptions; track option) {
          <mat-radio-button color="primary" [value]="option">{{
            t('REGISTRATION_RATE_APPLICATION_' + option)
          }}</mat-radio-button>
        }
      </mat-radio-group>
    } @else {
      <div class="flex items-center">
        <mat-icon class="ml-1">check</mat-icon>
        <em class="text-sm ml-2">{{ t('REGISTRATION_PAYMENT_' + data.settings.allowedOptions[0]) }}</em>
      </div>
    }

    <label class="my-2">{{ t('REGISTRATION_PRICING_TYPE') }} :</label>
    <mat-radio-group class="flex flex-col" [(ngModel)]="selectedPriceType">
      @for (type of data.settings.allowedTypes; track type) {
        <mat-radio-button color="primary" [value]="type">{{ t('REGISTRATION_PRICE_TYPE_' + type) }}</mat-radio-button>
      }
    </mat-radio-group>

    @if (selectedPriceType === EPriceType.OTHER) {
      <beathletics-discipline-by-group-selector
        appendTo="self"
        [data]="data.disciplinesByGroup"
        [selectedDisciplineRefs]="data.pricing?.disciplines"
        (disciplines)="selectedDisciplines = $event"
      ></beathletics-discipline-by-group-selector>
    }

    <mat-slide-toggle color="primary" class="mt-4 mb-2" [(ngModel)]="forAll">
      <span class="ml-2 text-base">{{ t('BASIC_APPLICABLE_TO_ALL') }}</span>
    </mat-slide-toggle>

    @if (!forAll) {
      <beathletics-prime-tree-selector
        closeIcon="check_box"
        [label]="t('BASIC_FED_CLUBS')"
        [dataTree]="data.clubList || null"
        [dataRefProperty]="'abbr'"
        [titleAndSubtitleLabel]="{ titleProp: 'abbr', subtitleProp: 'name' }"
        [selectedData]="data.pricing?.clubs || selectedClubs"
        (selectionChange)="setSelectedClubs($any($event))"
      ></beathletics-prime-tree-selector>
    }

    <div class="flex justify-center mt-8">
      <mat-form-field appearance="outline">
        <mat-label>{{ t('BASIC_PRICE') }}</mat-label>
        <input matInput type="number" min="0" step="0.01" [formControl]="price" />
        <mat-icon matSuffix>euro</mat-icon>
        @if (price.errors?.['required']) {
          <mat-error>{{ t('ERROR_PRICE_REQUIRED') }}</mat-error>
        } @else if (price.errors?.['negative']) {
          <mat-error>{{ t('ERROR_PRICE_NEGATIVE') }}</mat-error>
        } @else if (price.errors?.['precision']) {
          <mat-error>{{ t('ERROR_PRICE_PRECISION') }}</mat-error>
        }
      </mat-form-field>
    </div>
  </div>
  <div class="flex justify-between mt-6 px-1">
    <button mat-raised-button color="warn" class="w-24" [mat-dialog-close]="null">{{ t('BASIC_CANCEL') }}</button>
    <button
      mat-raised-button
      color="primary"
      class="w-24"
      [disabled]="
        (!selectedOption && data.settings.allowedOptions.length > 1) ||
        !selectedPriceType ||
        (selectedPriceType === EPriceType.OTHER && selectedDisciplines.length === 0) ||
        (!forAll && selectedClubs.length === 0) ||
        price.hasError('required') ||
        price.hasError('negative') ||
        price.hasError('precision')
      "
      (click)="savePricing()"
    >
      {{ t('BASIC_CONFIRM') }}
    </button>
  </div>
</section>
